import { useEffect } from 'react';

import { useMessageListenner } from '$src/hooks/client';
import { useAutoSelectPickupPoint } from '$src/hooks/client/useAutoSelectPickupPoint';
import { useRedirectSetData } from '$src/hooks/client/useRedirectSetData';
import { addIdentify, useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { usePrepareCart } from '$src/hooks/mutations';
import { useEstimatedShipping } from '$src/hooks/mutations/useEstimatedShipping';
import { useRecommendedDiscounts } from '$src/hooks/mutations/useRecommendDiscounts';
import { useCart, useShop, useUserLocationDetection } from '$src/hooks/queries';
import { useCartIdAtom, useCountryCodeAtom, useROFormMessageAtom } from '$src/hooks/state';
import { useCheckoutFeaturesAtom } from '$src/hooks/state/useCheckoutFeaturesAtom';
import { setXLanguageGatewayHeader, setXSessionHeaders } from '$src/http';
import { _postMessage, capturePosthogAnalytics, decodeHtmlEntities } from '$src/utils';

interface InitWrapperProps {
  children: React.ReactNode;
}

export const InitWrapper = ({ children }: InitWrapperProps) => {
  const message = useROFormMessageAtom();
  const {
    mutateAsync: estimateShipping,
    data: estimatedShipping,
    isPending: isEstimating,
  } = useEstimatedShipping();
  const {
    mutateAsync: recommendDiscount,
    data: recommendedDiscounts,
    isPending: fetchingDiscount,
  } = useRecommendedDiscounts();
  const { data: shop } = useShop();
  const { cartId } = useCartIdAtom();
  const { data: cart, refetch } = useCart();
  const { track } = useTrackAmplitude();
  useRedirectSetData();

  useEffect(() => {
    track('Checkout form opened');
  }, [track]);

  useEffect(() => {
    addIdentify('language', message?.language ?? '-');
  }, [message?.language]);

  useMessageListenner();
  useEffect(() => {
    setXSessionHeaders();
  }, []);

  const { data: location } = useUserLocationDetection();
  const { onCountryCodeChange, country } = useCountryCodeAtom();

  useEffect(() => {
    onCountryCodeChange(location?.location.country.code);
  }, [location, onCountryCodeChange]);

  useEffect(() => {
    setXLanguageGatewayHeader(message?.language ?? 'fr');
  }, [message?.language]);

  const {
    mutateAsync: prepareCart,
    status: prepareCartStatus,
    variables,
    // error: prepareCartError,
  } = usePrepareCart();

  const { features, onCheckoutFeaturesChange } = useCheckoutFeaturesAtom();

  useEffect(() => {
    if (!shop) return;

    onCheckoutFeaturesChange(shop);

    if ((shop?.backgroundUrl || '').length > 0) {
      track('Has background image');
    }
  }, [shop, onCheckoutFeaturesChange, track]);

  useEffect(() => {
    if (features && variables && message) {
      const trackFeatures = {
        ...features.reduce((result, feature) => ({ ...result, [feature]: 1 }), {}),
        'Source URL': message?.origin,
        variantIds: variables?.cart?.lineItems?.map(({ variantId }) => variantId).join(', '),
      };

      track('Features used', trackFeatures);
      features.forEach((feature) => capturePosthogAnalytics(feature));
    }
  }, [features, track, variables, message]);

  useEffect(() => {
    if (cart?.url) {
      // Martinique ues a mix of duties and taxes that we don't handle
      const isMartinique = cart.shipping.address?.countryCode === 'MQ';
      if (cart.totalDuties || isMartinique) {
        _postMessage({
          emitter: 'JUST_FORM',
          action: 'NAVIGATE',
          orderUrl: cart.url,
        });
      }
    }
  }, [cart]);

  useEffect(() => {
    if (
      !cartId &&
      message?.cart &&
      prepareCartStatus !== 'error' &&
      prepareCartStatus !== 'pending' &&
      prepareCartStatus !== 'success' &&
      country
    ) {
      prepareCart({
        cart: {
          ...message.cart,
          customerId: message.currentUser?.id,
          figpiiData: message.figpiiData,
          shippingAddress: message.currentUser?.address
            ? {
                firstName: decodeHtmlEntities(message.currentUser.firstName ?? ''),
                lastName: decodeHtmlEntities(message.currentUser.lastName ?? ''),
                address1: decodeHtmlEntities(message.currentUser.address.address1 ?? ''),
                city: decodeHtmlEntities(message.currentUser.address.city ?? ''),
                zip: decodeHtmlEntities(message.currentUser.address.zip ?? ''),
                country: decodeHtmlEntities(message.currentUser.address.country ?? ''),
                id: message.currentUser.address.id,
                phone: message.currentUser.phone ? decodeHtmlEntities(message.currentUser.phone) : undefined,
                address2: message.currentUser.address.address2
                  ? decodeHtmlEntities(message.currentUser.address.address2)
                  : undefined,
                province: message.currentUser.address.province
                  ? decodeHtmlEntities(message.currentUser.address.province)
                  : undefined,
                provinceCode: message.currentUser.address.province,
                countryCode: message.currentUser.address.countryCode ?? '',
              }
            : undefined,
        },
      });
    }
  }, [cartId, country, message, prepareCart, prepareCartStatus]);

  useEffect(() => {
    if (cart?.shipping?.ready === false || cart?.taxReady === false) {
      refetch();
    }
  }, [cart, refetch]);

  useEffect(() => {
    if (
      message?.cart.lineItems &&
      message?.cart.lineItems.length !== 0 &&
      prepareCartStatus !== 'error' &&
      prepareCartStatus !== 'pending' &&
      location?.location &&
      location?.location?.latitude !== 0 &&
      location?.location?.longitude !== 0 &&
      !estimatedShipping &&
      !isEstimating
    ) {
      estimateShipping({
        cart: message.cart,
        longitude: location?.location?.longitude,
        latitude: location?.location?.latitude,
      });
    }
  }, [message?.cart.lineItems, location, estimateShipping, prepareCartStatus]);

  useEffect(() => {
    if (
      message?.cart.lineItems &&
      message.cart?.lineItems.length !== 0 &&
      prepareCartStatus !== 'error' &&
      prepareCartStatus !== 'pending' &&
      location?.location &&
      location?.location?.latitude !== 0 &&
      !recommendedDiscounts &&
      !fetchingDiscount &&
      shop?.features?.includes('recommended_discounts')
    ) {
      recommendDiscount({
        cart: message.cart,
      });
    }
  }, [message?.cart.lineItems, location, recommendDiscount, prepareCartStatus]);

  useAutoSelectPickupPoint();
  return <>{children}</>;
};
